
import { handleMenuData } from '../../router/index.js';

export default {
    name: 'loginHl',
    components: {},
    data() {
        return {
            codeTime: 0, //验证码时间
            redirect: '',
            isVerification: true,
            isDisabled: false,
            identifyCode: '',
            identifyCodes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
            passwordShow: false, //密码是否显示
            codeActive: false, //发送验证码按钮颜色
            isClickCode: false, //是否点击过发送验证码
            qrcodeSrc: '',
            codeTime: 0, //验证码时间
            codeActive: false, //发送验证码按钮颜色
            isClickCode: false, //是否点击过发送验证码
            form: {
                code: '',
                username: '', //用户名
                password: '' //密码
            },

            dialogVisible: false,
            countdown: 0,
            timer: null, // 定时器变量
            resetPasswordForm: {
                username: '',
                code: '',
                mobileCode: '',
                password: '',
                confirmPassword: '',
                uuid: '',
                captcha: ''
            },
            resetPasswordRules: {
                username: [
                    { required: true, message: '用户名不能为空', trigger: 'blur' },
                    { min: 6, max: 50, message: '长度在 6 到 50 个字符', trigger: 'blur' }
                ],
                code: [{ required: true, message: '图片验证码不能为空', trigger: 'blur' }],
                mobileCode: [{ required: true, message: '短信验证码不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { validator: this.validateConfirmPassword, trigger: 'blur' }
                ]
            }
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    },
    methods: {
        // 获取图形验证码
        getCaptcha() {
            this.instance
                .get('/upms/api/captcha/generate', {})
                .then((res) => {
                    var data = res.data;
                    if (data.code === 200) {
                        this.form.code = '';
                        this.qrcodeSrc = data.data;
                    }
                })
                .catch((res) => {});
        },
        refreshRestCaptcha() {
            this.resetPasswordForm.captcha = '';
            this.getCaptcha();
        },

        resetPasswordDialogOpen() {
            this.resetPasswordFormClear();
            this.dialogVisible = true;
            this.resetPasswordForm.captcha = '';
            this.getCaptcha();
        },

        cancelResetPwd() {
            // this.resetPasswordFormClear()
            this.dialogVisible = false;
            this.resetPasswordForm = {
                username: '',
                code: '',
                mobileCode: '',
                password: '',
                confirmPassword: '',
                uuid: '',
                captcha: ''
            };
        },
        resetPasswordFormClear() {
            this.resetPasswordForm = JSON.parse(JSON.stringify(this.resetPasswordForm));
        },

        refreshCode() {
            this.qrcodeSrc = '';
            this.getCaptcha();
        },
        //发送验证码
        sendCode() {
            var _this = this;
            if (!this.form.username) {
                _this.$message.error('抱歉，请输入用户名');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (this.codeTime <= 0) {
                _this.codeTime = 60;
                this.isClickCode = true;
                this.instance.post('/upms/api/user/loginSms', this.form).then((res) => {
                    if (res.data.code == 200) {
                        var timer = setInterval(function () {
                            _this.codeTime--;
                            if (_this.codeTime <= 0) {
                                _this.isClickCode = false;
                                _this.codeActive = true;
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        _this.codeTime = 0;
                        _this.codeActive = false;
                        _this.isClickCode = false;
                        _this.$message.error(res.data.message);
                    }
                });
            }
        },
        //点击提交按钮的方法
        handleLogin() {
            var _this = this;
            if (!_this.form.username) {
                _this.$message.error('抱歉,请输入用户名!');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (!_this.form.code) {
                _this.$message.error('抱歉,请输入验证码!');
                return false;
            }
            this.form.uuid = this.qrcodeSrc.uuid;
            this.isDisabled = true;
            this.instance
                .post('/upms/api/user/loginFromUbi', this.form)
                .then((res) => {
                    var data = res.data;
                    this.isDisabled = false;
                    if (data.code == 200) {
                        this.$$setTimeToken(data.data.tokenVo);
                        localStorage.setItem('AUTH_TOKEN', data.data.accessToken);
                        localStorage.setItem('username', data.data.nickName);
                        localStorage.setItem('userInfo', JSON.stringify(data.data));
                        localStorage.setItem('resetRoutes', true);
                        this.userMenuTree();
                    } else {
                        this.refreshCode();
                        this.$message.error(data.message);
                        console.log(this.$message);
                        alert(data.message);
                    }
                })
                .catch((res) => {
                    this.isDisabled = false;
                });
        },
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', this.form)
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(data.data));
                        handleMenuData(data.data);
                        let _path = '/homepage/index';
                        let isHomePage = data.data.some((obj) => obj.path === '/homepage');

                        _path = isHomePage ? '/homepage/index' : '/caseInquiry/index';
                        this.$router.push({ path: _path });
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        getSmsCode() {
            if (this.resetPasswordForm.code === '') {
                this.$message.error('请输入图片验证码');
                return;
            }
            this.countdown = 60;
            setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
            this.resetPasswordForm.uuid = this.resetPasswordForm.captcha.uuid;
            let param = JSON.parse(JSON.stringify(this.resetPasswordForm));
            this.instance
                .post('/upms/api/user/resetPasswordSms', param)
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.$message.info('发送短信验证码成功');
                    } else {
                        this.$message.error(data.message);
                    }
                })
                .cache((res) => {
                    console.log(res);
                    this.$message.error('发送短信验证码失败! 稍后重试');
                });
        },
        validateConfirmPassword(rule, value, callback) {
            if (value === '') {
                callback(new Error('确认密码不能为空'));
            } else if (value !== this.resetPasswordForm.password) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        },
        resetPassword() {
            this.$refs.resetPasswordForm.validate((vaild) => {
                if (vaild) {
                    this.instance
                        .post('/upms/api/user/resetPassword', this.resetPasswordForm)
                        .then((res) => {
                            let data = res.data;
                            if (data.code === 200) {
                                this.dialogVisible = false;
                                this.$message.success('重置密码成功');
                            } else {
                                this.$message.error(data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$message.error('重置密码失败! 稍后重试');
                        });
                }
            });
        }
    },
    beforeDestroy() {
        // 组件销毁时清除定时器
        clearInterval(this.timer);
        this.timer = null;
    },
    activated() {
        this.getCaptcha();
    }
};
